import { Box, Button, Dialog, Drawer, Link, SvgIcon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks";
import Close from "../../assets/images/close.svg";
import Close2 from "../../assets/images/close2.svg";
import Networks from "../../assets/images/networks.svg";
import { ReactComponent as Down } from "../../assets/images/down.svg";
import Errors from "../../assets/images/error_.svg";
import { ReactComponent as Wallet } from "../../assets/images/header/wallet.svg";
import Logo from "../../assets/images/logo.svg";
import LogoMMs from "../../assets/images/h5logo.svg";
import Menu from "../../assets/images/menu.svg";
import { ReactComponent as Setting } from "../../assets/images/setting.svg";
import { ReactComponent as SettingM } from "../../assets/images/setting-m.svg";
import { CHAINNAME_LIST, NETWORK_LIST } from "src/constants/chains";
import { shorten } from "../../helpers";
import useClaim from "../../hooks/claim";
import "../../views/dashboard/dialog.scss";
import Checkbox from "../Checkbox/Checkbox2";
import "./header.scss";
import { toastSuccess } from "src/helpers/toastHelpers";
import { SupportedChainId } from "src/constants/chains";
import MenuListComposition from "src/components/Menu";
import EdePrice from "src/components/EdePrice";
import H5More from "./Haderh5";
import { BuyOrSellBtn } from "../Button/BuyOrSellBtn";
export default function Header(props) {
  const {
    slippageAmount,
    onInputValueChange,
    saveSettings,
    showPnlAfterFees,
    setShowPnlAfterFees,
    isPnlInLeverage,
    setIsPnlInLeverage,
    openSettings,
    showSetting,
    setShowSetting,
  } = props;
  const isSmallScreen = useMediaQuery("(max-width: 1380px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 1280px)");
  const smallScreen = useMediaQuery("(max-width: 680px)");
  const hsotry = useHistory();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const {
    connect,
    disconnect,
    connected,
    web3,
    address,
    chainID,
    switchNetwork2,
    Error,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleClose2 = () => {
    setShowInfo(false);
  };
  const handleClose3 = () => {
    setShowSetting(false);
  };
  const saveAndCloseSettings = () => {
    saveSettings();
  };

  const handlerLogout = disconnect;
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  let buttonText = "Connect Wallet";
  let clickFunc = () => {
    if (isConnected) {
      setShowInfo(true);
    } else {
      connect(true);
    }
  };

  let connectFn = () => {
    if (isConnected) {
      hsotry.push("/ESBT");
    } else {
      connect(true);
    }
  };

  if (isConnected) {
    buttonText = shorten(address); // "Disconnect";
    // clickFunc = disconnect;
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  const { claimTokens } = useClaim();
  const getTokens = async () => {
    await claimTokens();
  };

  const handlerNetwork = () => {
    setShow(true);
  };

  const handlerSwitch = (
    chainId,
    chainName,
    nativeCurrency,
    rpcUrl,
    blockExplorer
  ) => {
    // console.log(chainId, chainName, nativeCurrency, rpcUrl, blockExplorer);
    switchNetwork2(chainId, chainName, nativeCurrency, rpcUrl, blockExplorer);
  };

  const chain_ID = SupportedChainId[SupportedChainId[chainID]];

  const CorrectNetwork = !!SupportedChainId[chainID];

  return (
    <div className="header">
      <Box display="flex" alignItems="center" className="header-view">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="header"
        >
          <Box display="flex" alignItems="center" className="" height={"100%"}>
            {isVerySmallScreen && (
              <img
                src={Menu}
                className="ml-8"
                alt="menu"
                onClick={toggleDrawer}
              />
            )}
            <img
              src={isSmallScreen ? LogoMMs : Logo}
              alt="logo"
              className="logo"
            />
            {!isVerySmallScreen && (
              <Box
                display="flex"
                className="menuList font-14 font-weight-6"
                height={"100%"}
                gridGap={32}
                alignItems={"center"}
              >
                <Link
                  component={NavLink}
                  to={`/`}
                  className={`menuItem ${pathname == "/" && "menuItemActive"}`}
                >
                  Dashboard
                </Link>
                <Link
                  component={NavLink}
                  to={`/Trade`}
                  className={`menuItem ${
                    pathname == "/Trade" && "menuItemActive"
                  }`}
                >
                  Trade
                </Link>
                <Link
                  component={NavLink}
                  to={`/Earn`}
                  className={`menuItem ${
                    pathname == "/Earn" && "menuItemActive"
                  }`}
                >
                  Earn
                </Link>
                <Link
                  component={NavLink}
                  to={`/EUSD`}
                  className={`menuItem ${
                    pathname == "/EUSD" && "menuItemActive"
                  }`}
                >
                  EUSD
                </Link>
                <Link
                  component={NavLink}
                  to={`/DAO`}
                  className={`menuItem ${
                    pathname == "/DAO" && "menuItemActive"
                  }`}
                >
                  DAO
                </Link>
                <Link
                  component={NavLink}
                  to={`/Auction`}
                  className={`menuItem ${
                    pathname == "/Auction" && "menuItemActive"
                  }`}
                >
                  Auction
                </Link>
                <Link
                  component={NavLink}
                  to={`/Leaderboard`}
                  className={`menuItem ${
                    pathname == "/Leaderboard" && "menuItemActive"
                  }`}
                >
                  Leaderboard
                </Link>
                <Box>
                  <MenuListComposition />
                </Box>
              </Box>
            )}
          </Box>
          {isVerySmallScreen ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="connectBtnBox ml-12 mr-8"
              onClick={connectFn}
            >
              <Button variant="contained" className="connectBtn ">
                <SvgIcon
                  className="ml-4 downIcon"
                  component={Wallet}
                  viewBox="0 0 13 12"
                />
                <span className="ml-12">{buttonText}</span>
                <span className="connect_esbt font-weight-6">ESBT</span>
              </Button>
            </Box>
          ) : (
            <Box display="flex" className="color2 font-weight-5">
              {(chainID == 97 || chainID == 421613) && (
                <BuyOrSellBtn onClick={getTokens}>Faucet</BuyOrSellBtn>
              )}
              <div className="ml-12">
                <EdePrice></EdePrice>
              </div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={"16px"}
                ml={"26px"}
                onClick={openSettings}
              >
                <Setting className="seting" />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="switchBtnBox "
                onClick={handlerNetwork}
              >
                {!Error ? (
                  <Button variant="contained" className="switchBtn">
                    <img src={CHAINNAME_LIST[chainID].icon} height={20} />
                    <div className="ml-8">
                      {CHAINNAME_LIST[chainID].chainName}
                    </div>
                    <SvgIcon
                      className="ml-4 downIcon"
                      component={Down}
                      viewBox="0 0 15 9"
                    />
                  </Button>
                ) : (
                  <Button variant="contained" className="switchBtn">
                    <img src={Errors} height={20} />
                    <div className="ml-8">Unsupported</div>
                    <SvgIcon
                      className="ml-4 downIcon"
                      component={Down}
                      viewBox="0 0 15 9"
                    />
                  </Button>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="connectBtnBox ml-12 mr-8"
                onClick={connectFn}
              >
                <Button variant="contained" className="connectBtn ">
                  <SvgIcon
                    className="ml-4 downIcon"
                    component={Wallet}
                    viewBox="0 0 13 12"
                  />
                  <span className="ml-12">{buttonText}</span>
                  <span className="connect_esbt font-weight-6">ESBT</span>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={showSetting} onClose={handleClose3}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="dialogHeader"
            >
              <Box display="flex" alignItems="center">
                <Setting className="seting" />
                <div className="ml-8 font-18 font-weight-b text-uppercase">
                  Setting
                </div>
              </Box>
              <img src={Close} onClick={handleClose3} />
            </Box>
            <div className="settingInfo">
              <div className="slippage">
                <div className="font-14 font-weight-6 color9">
                  Allowed Slippage
                </div>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className="slippageAmount mt-16"
                >
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="amount-input flex-1"
                    value={slippageAmount}
                    onChange={onInputValueChange}
                  />
                  <div className="font-20 color2 font-weight-6 ml-16">%</div>
                </Box>
              </div>
              <Box
                display="flex"
                alignItems="center"
                className="displayItem mt-16"
              >
                <Checkbox
                  isChecked={showPnlAfterFees}
                  setIsChecked={setShowPnlAfterFees}
                >
                  <div className="font-14 font-weight-6">
                    Display PnL after fees
                  </div>
                </Checkbox>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className="displayItem mt-16"
              >
                <Checkbox
                  isChecked={isPnlInLeverage}
                  setIsChecked={setIsPnlInLeverage}
                >
                  <div className="font-14 font-weight-6">
                    Include PnL in leverage display
                  </div>
                </Checkbox>
              </Box>
            </div>
            <div className={`saveBtnBox`} onClick={saveAndCloseSettings}>
              <Button variant="contained" fullWidth className="saveBtn">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={showInfo} onClose={handleClose2}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="dialogHeader"
            >
              <div className="font-18 font-weight-b text-uppercase">
                My Wallet
              </div>
              <img src={Close} onClick={handleClose2} />
            </Box>
            <div className="networkList font-16">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`networkItem ${
                  connected && chainID == chain_ID ? "networkItemActive" : ""
                }`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className="networkItemLeft"
                >
                  <div className="ml-12">{shorten(address)}</div>
                </Box>
                <Box display="flex" alignItems="center" className="isConnected">
                  <div className="font-14">Connected</div>
                  <div className="connected"></div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems="center"
                className="mt-24"
              >
                <div
                  className="connectBtnBox"
                  onClick={() => {
                    copy(address),
                      // dispatch(success("copy success"));
                      toastSuccess("copy success");
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">Copy Address</span>
                  </Button>
                </div>
                <div
                  className={`connectBtnBox ${smallScreen && "mt-12"}`}
                  onClick={() => {
                    if (chainID == 97)
                      window.open(
                        `https://testnet.bscscan.com/address/${address}`
                      );
                    if (chainID == 56)
                      window.open(`https://bscscan.com/address/${address}`);
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">View in Explorer</span>
                  </Button>
                </div>
                <div
                  className={`getBtnBox ${smallScreen && "mt-12"}`}
                  onClick={handlerLogout}
                >
                  <Button variant="contained" className="getBtn">
                    Disconnect
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={show} onClose={handleClose}>
        <div className="dialogBg dialogBg-network">
          <div className="dialogContent">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="dialogHeader"
            >
              <Box display="flex" alignItems="center">
                <img src={Networks} />
                <div className="ml-8 font-18 font-weight-b text-uppercase">
                  Switch Networks
                </div>
              </Box>
              <img src={Close} onClick={handleClose} />
            </Box>
            <div className="networkList font-14">
              {NETWORK_LIST.map((item, index) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                  className={`networkItem ${
                    connected && chainID == item.chainId
                      ? "networkItemActive"
                      : ""
                  }`}
                  onClick={() =>
                    handlerSwitch(
                      item.chainId,
                      item.chainName,
                      item.nativeCurrency,
                      item.rpcUrl,
                      item.blockExplorerUrl
                    )
                  }
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className="networkItemLeft"
                  >
                    <img src={item.icon} height={30} />
                    <div className="ml-12 font-weight-6">{item.chainName}</div>
                  </Box>
                  {connected && chainID == item.chainId && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className="isConnected"
                    >
                      <div className="">Connected</div>
                      <div className="connected ml-8"></div>
                    </Box>
                  )}
                </Box>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
      <Drawer anchor={"right"} open={showDrawer} onClose={toggleDrawer}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className="drawerBg"
        >
          <Box flex={1} className="h5sai">
            <Box
              display="flex"
              justifyContent="flex-start"
              className="drawerClose"
              onClick={toggleDrawer}
            >
              <img src={Close2} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              className="menuList font-14 font-weight-6"
            >
              <Link
                component={NavLink}
                to={`/`}
                className={`menuItem ${pathname == "/" && "menuItemActiveh5"}`}
                onClick={toggleDrawer}
              >
                Dashboard
              </Link>
              <Link
                component={NavLink}
                to={`/Trade`}
                className={`menuItem ${
                  pathname == "/Trade" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                Trade
              </Link>
              <Link
                component={NavLink}
                to={`/Earn`}
                className={`menuItem ${
                  pathname == "/Earn" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                Earn
              </Link>
              <Link
                component={NavLink}
                to={`/EUSD`}
                className={`menuItem ${
                  pathname == "/EUSD" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                EUSD
              </Link>
              <Link
                component={NavLink}
                to={`/DAO`}
                className={`menuItem ${
                  pathname == "/DAO" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                DAO
              </Link>
              <Link
                component={NavLink}
                to={`/Auction`}
                className={`menuItem ${
                  pathname == "/Auction" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                Auction
              </Link>
              <Link
                component={NavLink}
                to={`/Leaderboard`}
                className={`menuItem ${
                  pathname == "/Leaderboard" && "menuItemActiveh5"
                }`}
                onClick={toggleDrawer}
              >
                Leaderboard
              </Link>
              <H5More toggleDrawer={toggleDrawer} />
            </Box>
          </Box>
          <Box className="linkList">
            <Box
              display={"flex"}
              flexDirection="column"
              p={"0 16px"}
              pt={"21px"}
            >
              <Box
                display="flex"
                justifyContent={"space-between"}
                gridGap={"8px"}
                className="color2 font-weight-5"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="switchBtnBox "
                  onClick={handlerNetwork}
                  style={{ width: "100%", marginRight: 0 }}
                >
                  {!Error ? (
                    <Button variant="contained" className="switchBtn">
                      <img src={CHAINNAME_LIST[chainID].icon} height={20} />
                      <div className="ml-8">
                        {CHAINNAME_LIST[chainID].chainName}
                      </div>
                      <SvgIcon
                        className="ml-4 downIcon"
                        component={Down}
                        viewBox="0 0 15 9"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="switchBtn connectBtnBox5"
                    >
                      <img src={Errors} height={20} />
                      <div className="ml-8">Unsupported</div>
                    </Button>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="connectBtnBox"
                  onClick={() => {
                    if (isConnected) {
                      hsotry.push("/ESBT");
                    } else {
                      clickFunc();
                    }
                    toggleDrawer();
                  }}
                >
                  <Button variant="contained" className="connectBtn ">
                    <SvgIcon
                      className="ml-4 downIcon"
                      component={Wallet}
                      viewBox="0 0 13 12"
                    />
                    <span className="ml-12">{buttonText}</span>
                    <span className="connect_esbt font-weight-6">ESBT</span>
                  </Button>
                </Box>
              </Box>

              <Box
                display={"flex"}
                justifyContent="center"
                mt={"24px"}
                gridGap={"24px"}
              >
                <EdePrice />

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="connectBtnBox connectBtnBox5  ml-12"
                  onClick={openSettings}
                >
                  <SvgIcon
                    className=""
                    component={SettingM}
                    viewBox="0 0 14 14"
                  />
                  <span className="seetinss ml-4">Settings</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
